import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage, GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import HubspotForm from 'react-hubspot-form'
import { navigate } from '@reach/router'
import styled from 'styled-components'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { RichText } from 'prismic-reactjs';

import htmlSerializer from '../utils/htmlSerializer';
import LayoutNew from "../components/layout-new";
import Seo from "../components/seo"

const WistiaVideo = loadable(() =>
  pMinDelay(import('../components/common/WistiaVideo.js'), 2000)
)

const StyledDiv = styled.div`
  min-height: 460px;
`

const PageInviteSpeak = () => {
  const data = useStaticQuery(graphql`
    query PageInviteSpeakQuery {
      prismicInviteUsToSpeakPage {
        _previewable
        data {
          page_title
          page_short_description
          page_hero_background {
            gatsbyImageData(
              width: 1920
            )
          }
          red_button_text
          red_button_url
          white_button_text
          intro_section_title
          intro_section_description
          general_section_title
          general_section_content {
            title
            description
          }
          video_section_title
          video_section_text {
            richText
          }
          wistia_id
          contact_section_title
          hubspot_form_id
        }
      }
    }
  `)

  const doc = data.prismicInviteUsToSpeakPage
  const imgHero = getImage(doc.data.page_hero_background)
  const imgSrc = getSrc(doc.data.page_hero_background)

  return (
    <LayoutNew>
      <Seo
        title={doc.data.page_title}
        description={doc.data.page_short_description ? doc.data.page_short_description : ""}
        image={imgSrc}
      />

      <div className="relative pt-14 xl:pt-20" style={{ display: "grid", background: "#000000", minHeight: "400px" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            opacity: "0.7",
            height: 700,
          }}
          alt="hero image"
          image={imgHero}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2">
            <div className="flex flex-col justify-center">
              <h2 className="text-white text-4xl md:text-7xl font-extrabold text-center mt-4 tracking-wide">
                {doc.data.page_title}
              </h2>

              <div className="flex flex-col items-center justify-center mt-8">                    
                <div className="mb-6">
                  <a href={doc.data.red_button_url} target='_blank' rel='noopener noreferrer'>
                    <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-xl md:text-2xl text-white uppercase tracking-wider px-3 md:px-6 py-3">
                      {doc.data.red_button_text}
                    </button>
                  </a>
                </div>

                
                <button
                  onClick={() => {navigate('#request')}} 
                  className="w-full mb-4 xl:mb-0 md:w-auto bg-white opacity-80 hover:opacity-90 text-xl md:text-2xl text-site-red uppercase tracking-wider px-14 md:px-20 py-3"
                >
                  {doc.data.white_button_text}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="absolute top-0 w-full flex flex-col items-center -mt-48 lg:-mt-40 xl:-mt-32">
          <div className="w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-site-green-button mx-auto py-12 px-6 md:px-16 rounded-3xl shadow-lg">
            <h2 className="text-white text-center uppercase font-black text-2xl md:text-3xl tracking-wider">{doc.data.intro_section_title}</h2>
            <p className="text-white font-medium text-center text-xl md:text-2xl mt-6 mb-0">
              {doc.data.intro_section_description}
            </p>
          </div>
        </div>

        <div className="bg-site-green-light pt-72 md:pt-60 pb-20">
          <StyledDiv className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center bg-white border-2 border-site-gray-dark mx-auto p-8 rounded-3xl shadow-lg">
            <div className="absolute top-0 bg-site-red py-4 px-6 md:px-16 rounded-full -mt-8 mx-auto">
              <h2 className="text-white text-base md:text-2xl font-bold uppercase text-center tracking-wider">{doc.data.video_section_title ? doc.data.video_section_title : "Section Title"}</h2>
            </div>
            <div className="w-full max-w-2xl mt-12 text-center">
              <p className="text-base md:text-xl text-center mb-6">
                <RichText render={doc.data.video_section_text.richText} htmlSerializer={htmlSerializer} />
              </p>
            </div>

            <div className="w-11/12 lg:w-10/12 max-w-screen-md mx-auto shadow-lg mt-6 mb-6">
              <WistiaVideo wistiaID={doc.data.wistia_id} />
            </div>
          </StyledDiv>
        </div>

        <div className="bg-white pt-28 pb-20">
          <div className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center bg-white border-2 border-site-red mx-auto p-8 rounded-3xl shadow-lg">
            <div className="absolute top-0 w-10/12 md:w-8/12 xl:w-1/2 bg-site-red p-4 rounded-full -mt-8 mx-auto">
              <h2 className="text-white text-xl md:text-3xl uppercase font-bold text-center tracking-wider">{doc.data.general_section_title}</h2>
            </div>

            <div className="w-full max-w-2xl flex flex-col justify-center mt-12 mb-8 z-10">
              {doc.data.general_section_content.map(item => {
                return (
                  <>
                    <h2 className="text-gray-900 text-2xl md:text-4xl text-center font-extrabold uppercase mb-4">{item.title}</h2>
                    <p className="text-2xl md:text-3xl text-center mb-6">{item.description}</p>
                  </>
                )
              })}
            </div>
            <div className="absolute bottom-0 opacity-10 z-0">
              <StaticImage
                src="../images/backgrounds/sra-icon-red.png"
                width={600}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="icon"
                className="rounded-3xl md:rounded-none"
              />
            </div>
          </div>
        </div>

        <div id="request" className="bg-gray-200 flex flex-col justify-center items-center border-t-2 border-site-red pt-6 pb-12 md:pb-16">
          <div className="w-full mx-auto px-3">
            <h2 className="text-gray-900 text-3xl md:text-4xl text-center font-extrabold tracking-wider mb-2 mt-16">{doc.data.contact_section_title}</h2>
          </div>

          <div className="bg-white w-11/12 max-w-lg mt-12 border-2 border-site-gray-dark rounded-3xl p-8 shadow-lg">
            <HubspotForm
              region='na1'
              portalId='23182726'
              formId={doc.data.hubspot_form_id}
              loading={<div className='italic'>Loading request form...</div>}
            />
          </div>
        </div>
      </div>
    </LayoutNew>
  )
}

export default withPrismicPreview(PageInviteSpeak)
